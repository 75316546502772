import React from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Img from 'next/image';

import Layout from '../Layout';
import profileCover from '../../../public/images/profileCover.jpg';

const styles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: 75,
    height: 470,
    width: '90%',
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
      height: 580,
      width: '80%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.3,
    marginBottom: 0,
  },
  textContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    paddingTop: 50,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
  },
  text: {
    fontFamily:
      'Bree Regular, Avenir Next, Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif',
    fontDisplay: 'swap',
  },
  link: {
    marginTop: 20,
    textDecoration: 'underline',
  },
}));

const NotFoundPage = () => {
  const classes = styles();
  const page = 'Página no encontrada | Not Found';
  return (
    <Layout title={page} description={page}>
      <Box className={classes.root}>
        <Img src={profileCover} alt="Prendas colgadas" className={classes.image} />
        <Box className={classes.textContainer}>
          <Typography
            gutterBottom
            variant="h2"
            element="p"
            className={classes.text}
          >
            Lo sentimos,
          </Typography>
          <Typography
            gutterBottom
            variant="h4"
            element="p"
            className={classes.text}
          >
            esta página no existe
          </Typography>
          <Typography
            variant="body1"
            element="p"
            color="primary"
            className={classes.link}
          >
            <Link href="/">Vuelve a lastraperas.com</Link>
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
